<template>
  <validation-observer ref="form-no">
    <b-row>
      <b-col
        md="12"
      >
        <b-form-group>
          <b-form-radio-group
            v-model="mode"
            :options="options"
            button-variant="outline-secondary"
            class="verifikasi-kegiatan-radios"
            size="lg"
            name="verifikasi"
            buttons
          />
        </b-form-group>
      </b-col>
      <template v-if="mode == 'no'">
        <b-col
          cols="12"
        >
          <h5>Penjelasan Kegiatan</h5>
          <p>Uraikan secara singkat dan jelas terkait kegiatan</p>
          <b-form-group
            label="Latar Belakang Pengadaan"
            label-for="vi-summary-latar"
          >
            <validation-provider
              #default="{ errors }"
              name="Latar Belakang Pengadaan"
              rules="required"
            >
              <b-form-textarea
                id="vi-summary-latar"
                v-model="summary.latar"
                rows="5"
                required
                name="latar"
                placeholder="Latar Belakang Pengadaan"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Urgensi dan Tujuan Pengadaan"
            label-for="vi-summary-urgensi"
          >
            <validation-provider
              #default="{ errors }"
              name="Urgensi dan Tujuan Pengadaan"
              rules="required"
            >
              <b-form-textarea
                id="vi-summary-urgensi"
                v-model="summary.urgensi"
                rows="5"
                required
                name="urgensi"
                placeholder="Urgensi dan Tujuan Pengadaan"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Kegunaan dan Manfaat Item Pengadaan"
            label-for="vi-summary-manfaat"
          >
            <validation-provider
              #default="{ errors }"
              name="Kegunaan dan Manfaat Item Pengadaan"
              rules="required"
            >
              <b-form-textarea
                id="vi-summary-manfaat"
                v-model="summary.manfaat"
                rows="5"
                required
                name="manfaat"
                placeholder="Kegunaan dan Manfaat Item Pengadaan"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Dan hal lainnya yg perlu dijelaskan (contoh : kondisi existing, amanat hukum terkait, hasil koordinasi) "
            label-for="vi-summary-lainnya"
          >
            <validation-provider
              #default="{ errors }"
              name="Dan hal lainnya yg perlu dijelaskan"
              rules="required"
            >
              <b-form-textarea
                id="vi-summary-lainnya"
                v-model="summary.lainnya"
                rows="5"
                required
                name="lainnya"
                placeholder="Dan hal lainnya yg perlu dijelaskan (contoh : kondisi existing, amanat hukum terkait, hasil koordinasi) "
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="detailData.jenis_pengadaan_id === 6"
          cols="12"
        >
          <h5>Spesifikasi Teknis</h5>
          <p>Uraikan secara singkat dan jelas terkait Spesifikasi Teknis Belanja Data untuk masing-masing jenis belanja Data</p>
          <b-form-group
            label="Mekanisme Pengumpulan Data"
            label-for="vi-teknis-fungsi"
          >
            <validation-provider
              #default="{ errors }"
              name="Mekanisme Pengumpulan Data"
              rules="required"
            >
              <b-form-textarea
                id="vi-teknis-fungsi"
                v-model="teknis.fungsi"
                rows="5"
                required
                name="fungsi"
                placeholder="Mekanisme Pengumpulan Data"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Penerapan Prinsip SDI Dalam Data"
            label-for="vi-teknis-layanan"
          >
            <validation-provider
              #default="{ errors }"
              name="Penerapan Prinsip SDI Dalam Data"
              rules="required"
            >
              <b-form-textarea
                id="vi-teknis-layanan"
                v-model="teknis.layanan"
                rows="5"
                required
                name="layanan"
                placeholder="Penerapan Prinsip SDI Dalam Data"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Potensi K/L Yang Memproduksi Data"
            label-for="vi-teknis-spek"
          >
            <validation-provider
              #default="{ errors }"
              name="Potensi K/L Yang Memproduksi Data"
              rules="required"
            >
              <b-form-textarea
                id="vi-teknis-spek"
                v-model="teknis.spek"
                rows="5"
                required
                name="spek"
                placeholder="Potensi K/L Yang Memproduksi Data"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-else
          cols="12"
        >
          <h5>Penjelasan Teknis</h5>
          <p>Uraikan secara singkat dan jelas terkait Spesifikasi Teknis Belanja </p>
          <b-form-group
            label="Fungsi Utama"
            label-for="vi-teknis-fungsi"
          >
            <validation-provider
              #default="{ errors }"
              name="Fungsi Utama"
              rules="required"
            >
              <b-form-textarea
                id="vi-teknis-fungsi"
                v-model="teknis.fungsi"
                rows="5"
                required
                name="fungsi"
                placeholder="Fungsi Utama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Layanan Yang Didukung"
            label-for="vi-teknis-layanan"
          >
            <validation-provider
              #default="{ errors }"
              name="Layanan Yang Didukung"
              rules="required"
            >
              <b-form-textarea
                id="vi-teknis-layanan"
                v-model="teknis.layanan"
                rows="5"
                required
                name="layanan"
                placeholder="Layanan Yang Didukung"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Spesifikasi Teknis Item Pengadaan"
            label-for="vi-teknis-spek"
          >
            <p>(Meliputi jumlah unit, type, platform, procesor, storage, memory, warranty, license, dst)</p>
            <validation-provider
              #default="{ errors }"
              name="Spesifikasi Teknis Item Pengadaan"
              rules="required"
            >
              <b-form-textarea
                id="vi-teknis-spek"
                v-model="teknis.spek"
                rows="5"
                required
                name="spek"
                placeholder="Spesifikasi Teknis Item Pengadaan"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="mb-1"
        >
          <b-form-group
            label="Dokumen Pendukung"
            label-for="file_tambahan"
          >
            <b-form-file
              v-model="file1"
              name="file_tambahan"
              class="mt-1"
            />
          <!-- <b-form-file
            v-model="file1"
            name="file_tambahan"
            class="mt-1"
            multiple
          /> -->
          </b-form-group>
        </b-col>
      </template>
      <b-col
        v-if="errorStat"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            Error Found: {{ errorMsg }}
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="mode"
      >
        <b-button
          variant="outline-success"
          class="mt-0 mt-md-1"
          size="sm"
          @click="checkSave"
        >
          <span>Kirim Konfirmasi</span>
        </b-button>
        <b-button
          v-if="dataSaved"
          variant="flat-success"
          size="sm"
          class="mt-0 mt-md-2 ml-50"
        >
          <feather-icon
            icon="CheckCircleIcon"
            size="14"
          /> Data Konfirmasi Berhasil Disimpan
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BAlert, BFormFile, BFormRadioGroup, BFormGroup, BRow, BCol, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BFormTextarea,
    BFormRadioGroup,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    componentType: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      required,
      dataSaved: false,
      mode: '',
      summary: {
        latar: '',
        urgensi: '',
        manfaat: '',
        lainnya: '',
      },
      summaryTitle: {
        latar: 'Latar Belakang',
        urgensi: 'Urgensi dan Tujuan',
        manfaat: 'Kegunaan dan Manfaat',
        lainnya: 'Penjelasan Lainnya',
      },
      teknis: {
        fungsi: '',
        layanan: '',
        spek: '',
      },
      teknisTitle: {
        fungsi: 'Fungsi Utama',
        layanan: 'Layanan Yang Didukung',
        spek: 'Spesifikasi Teknis Item Pengadaan',
      },
      dataTitle: {
        fungsi: 'Mekanisme Pengumpulan Data',
        layanan: 'Penerapan Prinsip SDI Dalam Data',
        spek: 'Potensi K/L Yang Memproduksi Data',
      },
      options: [
        { text: 'Setuju', value: 'yes', variant: 'text-info' },
        { text: 'Tidak Setuju', value: 'no', variant: 'text-danger' },
      ],
      // file1: [],
      file1: null,
      errorStat: false,
      errorMsg: '',
      penjelasan_kegiatan: '',
      penjelasan_teknis: '',
    }
  },
  methods: {
    checkSave() {
      this.$refs['form-no'].validate()
        .then(success => {
          if (success) {
            this.updateSave()
          } else {
            this.errorStat = true
            this.errorMsg = 'Terdapat Kesalahan Input Penjelasan, Periksa kembali input Penjelasan Anda'
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.message
        })
    },
    updateSave() {
      Object.keys(this.summary).forEach(key => {
        this.penjelasan_kegiatan += `${this.summaryTitle[key]}: \n\n ${this.summary[key]} \n\n `
      })
      if (this.detailData.jenis_pengadaan_id === 6) {
        Object.keys(this.teknis).forEach(key => {
          this.penjelasan_teknis += `${this.dataTitle[key]}: \n\n ${this.teknis[key]} \n\n `
        })
      } else {
        Object.keys(this.teknis).forEach(key => {
          this.penjelasan_teknis += `${this.teknisTitle[key]}: \n\n ${this.teknis[key]} \n\n `
        })
      }
      const verif = {
        data_utama_id: this.detailData.data_utama_id,
        is_agreed: (this.mode === 'yes') ? 1 : 0,
        keterangan: this.penjelasan_kegiatan,
        spesifikasi_teknis: this.penjelasan_teknis,
      }
      if (verif.is_agreed === 0 && verif.keterangan === '') {
        this.errorStat = true
        this.errorMsg = 'Penjelasan tidak boleh kosong'
      } else {
        if (verif.is_agreed === 1) {
          this.$delete(verif, 'keterangan')
          this.$delete(verif, 'spesifikasi_teknis')
        }
        if (this.componentType === 'aplikasi') {
          verif.aplikasi_id = this.detailData.aplikasi_id
        } else if (this.componentType === 'infrastruktur') {
          verif.infrastruktur_id = this.detailData.infrastruktur_id
        } else if (this.componentType === 'datainformasi') {
          verif.data_informasi_id = this.detailData.data_informasi_id
        } else if (this.componentType === 'non-teknis') {
          verif.non_teknis_id = this.detailData.non_teknis_id
        }
        this.sendRekomendasi(verif)
      }
    },
    sendRekomendasi(verif) {
      let targetUrl = '/recommendation/konfirmasi-app'
      if (this.componentType === 'infrastruktur') {
        targetUrl = '/recommendation/konfirmasi-infra'
      } else if (this.componentType === 'datainformasi') {
        targetUrl = '/recommendation/konfirmasi-data'
      } else if (this.componentType === 'non-teknis') {
        targetUrl = '/recommendation/konfirmasi-non-teknis'
      }
      this.$http.post(targetUrl, verif, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            const confirmed = res.data.data.confirmation_id
            const status = true

            const promise2 = new Promise(resolve => {
              if (this.file1 !== null) {
                const formFile = new FormData()

                if (this.componentType === 'aplikasi') {
                  formFile.append('app_infra', 'app')
                } else if (this.componentType === 'infrastruktur') {
                  formFile.append('app_infra', 'infra')
                } else if (this.componentType === 'datainformasi') {
                  formFile.append('app_infra', 'data')
                } else if (this.componentType === 'non-teknis') {
                  formFile.append('app_infra', 'non-teknis')
                }
                formFile.append('additional_file', this.file1)

                this.$http.post(`/recommendation/rekomendasi-konfirmasi-upload/${confirmed}`, formFile, {
                  params: {
                    token: localStorage.getItem('userToken'),
                  },
                })
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      resolve(true)
                    } else {
                      this.errorStat = true
                      this.errorMsg = resp.data.error
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    this.errorStat = true
                    this.errorMsg = error.response.data.error
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })
            // const promise2 = new Promise(resolve => {
            //   if (this.file1.length !== 0) {
            //     for (let i = 0; i < this.file1.length; i += 1) {
            //       const formFile = new FormData()
            //       formFile.append('additional_file', this.file1[i])

            //       this.$http.post(`/recommendation/rekomendasi-konfirmasi-upload/${confirmed}`, formFile, {
            //         params: {
            //           token: localStorage.getItem('userToken'),
            //         },
            //       })
            //         .then(resolve(true))
            //     }
            //   } else {
            //     resolve(true)
            //   }
            // })
            Promise.all([promise2, status]).then(values => {
              if (values[0] && values[1]) {
                this.dataSaved = true
                this.file1 = null

                setTimeout(() => {
                  this.dataSaved = false
                  this.errorStat = false
                  this.errorMsg = ''

                  this.$router.replace('/pengajuan/rekomendasi-sementara')
                  return values
                }, 1000)
              }
            })
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
