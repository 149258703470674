<template>
  <section id="detail-kegiatan">
    <b-row
      v-if="app_view"
    >
      <b-col
        lg="3"
        cols="12"
      >
        <b-card class="shadow-none">
          <!-- about -->
          <h2 class="text-center mb-2">
            {{ dataProgram.nama_kegiatan }}
          </h2>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="ClipboardIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>No. Tiket</small>
              <h4 class="mb-0">
                {{ dataProgram.uuid }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="UserIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Penanggung Jawab</small>
              <h4 class="mb-0">
                {{ dataProgram.pic }} ({{ dataProgram.pic_number }})
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Tahun Anggaran</small>
              <h4 class="mb-0">
                {{ dataProgram.tahun_anggaran }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="ShoppingBagIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Total Anggaran</small>
              <h4 class="mb-0">
                {{ Number(dataProgram.total_anggaran).toLocaleString() }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="BookOpenIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Kode Mata Anggaran</small>
              <h4 class="mb-0">
                {{ dataProgram.kode_anggaran }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="ShoppingCartIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Jenis Belanja</small>
              <h4 class="mb-0">
                {{ dataProgram.jenis_belanja }}
                <feather-icon
                  v-if="dataProgram.prioritas_nasional === 1"
                  icon="StarIcon"
                  size="18"
                />
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="ListIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Jenis Pengadaan</small>
              <h4 class="mb-0">
                {{ dataProgram.jenis_pengadaan }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="BriefcaseIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Sumber Dana</small>
              <h4 class="mb-0">
                {{ dataProgram.sumber_dana }}
              </h4>
            </div>
          </div>
          <!-- <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="ClockIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Perubahan Terakhir</small>
              <h4 class="mb-0">
                {{ dataProgram.update_ts | formatDate }}
              </h4>
            </div>
          </div> -->
        </b-card>
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <b-card
          header="Aplikasi"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <b-row>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>DOMAIN APLIKASI</h5>
              <p>{{ dataAplikasi.domain_aplikasi }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>AREA APLIKASI</h5>
              <p>{{ dataAplikasi.area_aplikasi }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>NAMA APLIKASI</h5>
              <p>{{ dataAplikasi.nama_aplikasi }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>STATUS KEGIATAN</h5>
              <p>{{ dataAplikasi.status_kegiatan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>TOTAL ANGGARAN</h5>
              <p>{{ dataAplikasi.total_anggaran ? Number(dataAplikasi.total_anggaran).toLocaleString() : 0 }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>SUMBER DANA</h5>
              <p>{{ dataAplikasi.sumber_dana }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>JENIS BELANJA</h5>
              <p>{{ dataAplikasi.jenis_belanja }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>JENIS PENGADAAN</h5>
              <p>{{ dataAplikasi.jenis_pengadaan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>FUNGSI APLIKASI</h5>
              <p>{{ dataAplikasi.fungsi }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>LUARAN / OUTPUT</h5>
              <p>{{ dataAplikasi.output }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>LAYANAN YANG DIDUKUNG</h5>
              <p>{{ dataAplikasi.area_layanan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>DATA YANG DIGUNAKAN</h5>
              <p>{{ dataAplikasi.data_tematik }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>BASIS APLIKASI</h5>
              <p>
                <b-badge
                  v-for="(data, index) in dataAplikasi.basis_aplikasi"
                  :key="index"
                  class="mr-25 mb-25"
                >
                  {{ data.nama }}
                </b-badge>
              </p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>SERVER APLIKASI</h5>
              <p>{{ dataAplikasi.server }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>TIPE LISENSI BAHASA PEMROGRAMAN</h5>
              <p>{{ dataAplikasi.lisensi_aplikasi }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>BAHASA PEMROGRAMAN</h5>
              <p>
                <b-badge
                  v-for="(data, index) in dataAplikasi.bahasa_pemrograman_aplikasi"
                  :key="index"
                  class="mr-25 mb-25"
                >
                  {{ data.nama }}
                </b-badge>
              </p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>KERANGKA/FRAMEWORK PENGEMBANGAN</h5>
              <p>
                <b-badge
                  v-for="(data, index) in dataAplikasi.framework_aplikasi"
                  :key="index"
                  class="mr-25 mb-25"
                >
                  {{ data.nama }}
                </b-badge>
              </p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>BASIS DATA</h5>
              <p>
                <b-badge
                  v-for="(data, index) in dataAplikasi.basidata_aplikasi"
                  :key="index"
                  class="mr-25 mb-25"
                >
                  {{ data.nama }}
                </b-badge>
              </p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>UNIT PENGEMBANG</h5>
              <p>{{ dataAplikasi.unit_pengembang }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>UNIT OPERASIONAL TEKNOLOGI</h5>
              <p>{{ dataAplikasi.unit_operasional_teknologi }}</p>
            </b-col>
            <b-col
              v-if="dataAplikasi.file_kak !== null"
              md="6"
              class="mb-50"
            >
              <h5>Kerangka Acuan Kerja</h5>
              <p>
                <b-button
                  variant="success"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="viewFile(dataAplikasi.kak_uri)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="14"
                  /></b-button>
                <b-link
                  :href="getDoc(dataAplikasi.kak_uri)"
                  class="btn btn-sm btn-info mr-25 mb-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="14"
                  /></b-link>
                <small>{{ dataAplikasi.kak_uri.substring(dataAplikasi.kak_uri.lastIndexOf('/') + 1) }}</small>
              </p>
            </b-col>
            <b-col
              v-if="dataAplikasi.additional_docs.length !== 0"
              md="6"
              class="mb-50"
            >
              <h5>Dokumen Tambahan</h5>
              <p
                v-for="(data, index) in dataAplikasi.additional_docs"
                :id="`dt-${index}`"
                :key="index"
              >
                <b-button
                  variant="success"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="viewFile(data.document_uri)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="14"
                  /></b-button>
                <b-link
                  :href="getDoc(data.document_uri)"
                  class="btn btn-sm btn-info mr-25 mb-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="14"
                  />
                </b-link>
                <small>{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}</small>
              </p>
            </b-col>
            <b-col md="12">
              <h5>URAIAN APLIKASI</h5>
              <p>{{ dataAplikasi.uraian_aplikasi }}</p>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header="Rekapitulasi Rekomendasi Sementara"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <summary-rekomendasi
            :rekap-lanjut="listRekomendasi.continue"
            :rekap-tidak="listRekomendasi.postpone"
          />
        </b-card>

        <b-card
          v-if="view_type === 'ask'"
          header="Apakah anda setuju dengan rekomendasi di atas?"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <modul-tanya-rekomendasi
            :detail-data="dataAplikasi"
            component-type="aplikasi"
          />
        </b-card>

        <template
          v-else-if="view_type === 'konfirmasi'"
        >
          <b-card
            header="Tanggapan K/L"
            header-tag="h4"
            header-class="border-bottom mb-2"
          >
            <summary-tanggapan
              :component-id="Number(app_id)"
              :detail-program="dataAplikasi"
              step-rekomendasi="aplikasi"
              @view-file="viewFile"
            />
          </b-card>

          <b-card
            header="Hasil Pembahasan Peninjauan Kembali"
            header-tag="h4"
            header-class="border-bottom mb-2"
          >
            <modul-rekomendasi-konfirmasi
              :component-id="Number(app_id)"
              :detail-program="dataAplikasi"
              step-rekomendasi="aplikasi"
            />
          </b-card>
        </template>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BCardText, BRow, BCol, BCard, BLink, BBadge, BButton, BModal, BAvatar,
} from 'bootstrap-vue'
import VuePdfApp from 'vue-pdf-app'

import SummaryRekomendasi from './SummaryRekomendasi.vue'
import SummaryTanggapan from './SummaryTanggapan.vue'
import ModulRekomendasiKonfirmasi from './ModulRekomendasiKonfirmasi.vue'
import ModulTanyaRekomendasi from './ModulTanyaRekomendasi.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BBadge,
    BButton,
    BAvatar,
    BModal,
    BCardText,

    SummaryRekomendasi,
    SummaryTanggapan,
    ModulTanyaRekomendasi,
    ModulRekomendasiKonfirmasi,
    VuePdfApp,
  },
  data() {
    return {
      app_id: '',
      app_view: false,
      view_type: '',
      fileDoc: '',
      rangkuman: '',
      dataProgram: {},
      dataAplikasi: {},
      listRekomendasi: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  mounted() {
    this.data_id = this.$route.params.utama
    this.app_id = this.$route.params.id
    this.view_type = this.$route.params.type

    this.$http.get('/clearance/applications', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_id,
        aplikasi_id: this.app_id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          res.data.data.map(value => {
            this.dataAplikasi = value
            return true
          })
          this.getProgram()
          this.openRekomendasi()
        } else {
          this.$router.replace('error-404')
        }
      })
      .catch(error => {
        console.log(error)
        this.$router.replace('error-404')
      })
  },
  methods: {
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    getProgram() {
      this.$http.get('/clearance/core-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataProgram = res.data.data
            this.app_view = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    openRekomendasi() {
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_id,
        aplikasi_id: this.app_id,
        type: 'app',
      }
      this.$http.get('/recommendation/get-rekomendasi-sementara', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
          }
        })
    },
  },
}
</script>
